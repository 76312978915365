import React, { useState, useEffect, useRef, useContext } from "react";
import Icon from "components/Icon";
import Uploadfile from "../../../components/uploadFiles"
import AddPromptModal from "components/AddPromptModal";
import { PromptsContext } from "context/promptsContext";
import { useUsersContext } from "context/usersContext";

const ChatInput = ({
  showAttach,
  setShowAttach,
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  setFooterHeight,
  footerHeight,
  setGoBottomBtn,
  uploadFilefrominput,
  agencyInfo,
  chatId,
  onFileSelect,
  selectedFiles,
  updatestateodfiles,
  setFileType
}) => {
  const textareaRef = useRef(null);
  const detectEnterPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      updatestateodfiles([]);
      if (newMessage.trim()) {
        if (placeholdervalue === "What can your AI help you with next?") {
          submitNewMessage(newMessage, 'savetodb', "notimage");
        } else {
          submitNewMessage(newMessage, 'savetodb', "image");
          setplaceholdervalue("What can your AI help you with next?")
        }
      } else {

      }
    };
  }

  // useEffect(() => {
  //   const adjustHeight = () => {
  //     // Reset height to shrink if content is removed
  //     textareaRef.current.style.height = '64px';

  //     // Set height based on scrollHeight, but not more than the max height
  //     const maxHeight = 200; // Set your maximum height here
  //     const newHeight = Math.min(textareaRef.current.scrollHeight, maxHeight);
  //     textareaRef.current.style.height = `${newHeight}px`;
  //     setFooterHeight(`${newHeight}px`);
  //   };
  //   adjustHeight();
  //   window.addEventListener('resize', adjustHeight);
  //   return () => {
  //     window.removeEventListener('resize', adjustHeight);
  //   };
  // }, [newMessage]);
  const [isOpenAddPromptModal, setIsAddPromptModal] = useState(false);
  const { promptsData, getPrompts } = useContext(PromptsContext);
  const [placeholdervalue, setplaceholdervalue] = useState("What can your AI help you with next?");

  const addPromptHandle = () => {
    setIsAddPromptModal(true);
  }
  const justCloseIt = () => {
    setIsAddPromptModal(false);
  }



  return (
    <div className="chat__input-wrapper" >
      {/* ... other components ... */}

      <AddPromptModal
        isModalOpen={isOpenAddPromptModal}
        categories={promptsData?.prompt_category?.filter(obj => obj.type != 'super-admin')}
        subCategories={promptsData?.prompt_sub_category?.filter(obj => obj.type != 'super-admin')}
        justCloseIt={justCloseIt}
        getUpdatePromptsData={getPrompts}

      />

      {/* <div className="position-relative text-area" style={{ marginBottom: inputHeight }}></div> */}
      <input
        ref={textareaRef}
        className="v3chat__input"
        placeholder={placeholdervalue}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={detectEnterPress}
        style={{
          overflowY: 'auto',
          position: 'relative',
          bottom: "0px",
          width: "100%",
          paddingLeft: "60px",
          borderRadius: selectedFiles.length > 0 ? '0px 0px 22px 22px' : ''
        }}
      />

      <button aria-label="Send message" onClick={() => submitNewMessage(newMessage, 'savetodb', placeholdervalue === "What can your AI help you with next?" ? "notimage" : "image")} className="sendChatBtn">
        <Icon id="sendChat" className="chat__input-icon" />
      </button>


      <Uploadfile classname="filecogChatBtn" chatId={chatId} onFileSelect={onFileSelect} setFileType={setFileType} />
      {/* 
      <button aria-label="Send message" className="chat__input-icon-2" onClick={() => setplaceholdervalue("Type your imagination and watch it come to life!")}>
        <Icon id="uploadimgIcon" />
      </button> */}

    </div>
  );
};

export default ChatInput;
