import React, { useState } from "react";
import Icon from "components/Icon";
import "./index.css";
import localforage from "localforage";
const BASE_URL = `https://apichat.maybetech.com/api/v1`;

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="delete-modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const DeleteChatModal = ({ modelType,isModalOpen, yesDelete, justCloseIt, contact, type }) => {
  return (
    <div>
      <Modal isOpen={isModalOpen}>
        <div className="scrollable-modal-content" >
          <b >
            Delete {modelType}
          </b>

          <p className="delete_modalText">
            Are you sure you want to delete {type === 'prompt' ? (contact?.title != '' && contact?.title != undefined) ? `"${contact?.title}"` : '' : (contact?.name != '' && contact?.name != undefined) ? `"${contact?.name}"` : ''}
            <br />
            This action cannot be undone.
          </p>

          <div className="delete_modalBtnGroup">
            <button type="submit" className="delete_agreeBtn" onClick={yesDelete}>
              Yes, delete {modelType}
            </button>

            <p className="delete_disagreeBtn" onClick={justCloseIt}>
              No, go back
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteChatModal;
