// promptsContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import env from "config";
import SsoHandler from "utils/SSOhandler";

export const PromptsContext = createContext();
export const usePromptsContaxt = () => useContext(PromptsContext);

export const PromptsProvider = ({ children }) => {
  const { SSO, checkSSO } = SsoHandler();

  const BASE_URL = `${env.API_URL}/v1`;
  const [promptsData, setPromptsData] = useState();
  const [chatCategories, setChatCategories] = useState([
    { name: "Uncategorised" },
  ]);
  const [favouritesData, setFavouritesData] = useState(null);
  const [promptsError, setPromptsError] = useState();

  const getPrompts = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/prompts/prompt_data?userId=${SSO?.userUUID}&location_id=${SSO?.id}&agency_id=${SSO?.agency_id}`
      );
      setPromptsData(response.data.data);
    } catch (error) {
      setPromptsData([]);
      setPromptsError(error);
    }
  };

  const getChatCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/category?userId=${SSO?.userUUID}&location_id=${SSO?.id}&agency_id=${SSO?.agency_id}`
      );
      setChatCategories([{ name: "Uncategorised" }, ...response.data.data]);
    } catch (error) {
      setPromptsError(error);
    }
  };

  const getFavourites = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/chat/get/favourites?userId=${SSO?.userUUID}&location_id=${SSO?.id}&agency_id=${SSO?.agency_id}`
      );
      setFavouritesData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onLoad = (SSO) => {
    if (SSO !== "" && SSO !== undefined) {
      console.log('checkehklkjalfssoososo', SSO)
      getPrompts();
      getFavourites();
      getChatCategories();
    }
  };
  useEffect(() => {
    onLoad(SSO);
  }, [SSO]);
  const handleRefreshData = () => {
    getPrompts();
    getFavourites();
    getChatCategories();
  }
  useEffect(() => {
    checkSSO();
  }, []);
  return (
    <PromptsContext.Provider
      value={{
        promptsData,
        promptsError,
        getPrompts,
        favouritesData,
        getFavourites,
        chatCategories,
        getChatCategories,
        handleRefreshData,

      }}
    >
      {children}
    </PromptsContext.Provider>
  );
};
